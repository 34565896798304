import React from 'react';
import ComponentWrapper from '../../../components/layout/ComponentWrapper';
import { StudyDesignOverview, StudyDesignArms, StudyCopyBlock } from '../index';

const UC_1L = () => (
    
    <ComponentWrapper>
        <StudyDesignOverview title="Study Overview" 
        description=" <p>A multicenter, single-arm, open-label trial</p>" 
        data={[
            [
                {
                    title: 'Patient Population',
                    description: `
                        <p>370 patients with locally advanced or metastatic Urothelial Carcinoma who had 1 or more comorbidities, including patients who are not eligible for platinum-containing chemotherapy. The trial excluded patients with autoimmune disease or a medical condition that required immunosuppression.</p>
                    `,
                    type: 'box-gradient'
                }
            ]
        ]} />
    
        <StudyDesignArms 
            title="Study Arms" 
            arms={[
                {
                    title: "KEYTRUDA",
                    data: [
                        [
                            {
                                title: 'Treatment',
                                description: `KEYTRUDA 200&nbsp;mg every 3 weeks (Q3W) as a 30-minute IV infusion`,
                                type: 'box-green'
                            }
                        ],
                        [
                            {
                                type: 'arrow-green'
                            }
                        ],
                        [
                            {
                                title: 'Treatment Duration',
                                description: `
                                    <p>Treatment continued until disease progression, unacceptable toxicity, or for up to 24 months in patients without disease progression.</p>
                                    <p>Patients with initial radiographic disease progression could receive additional doses of treatment during confirmation of progression unless disease progression was symptomatic, was rapidly progressive, required urgent intervention, or occurred with a decline in performance status.</p>
                                `
                            }
                        ]
                    ]
                }
            ]} 
        />
    
        <StudyCopyBlock 
            title="End Points"
            description={` 
                <p>Major efficacy outcomes measures: ORR and DoR as assessed by BICR according to RECIST v1.1, modified to follow a maximum of 10 target lesions and a maximum of 5 target lesions per organ.</p>
                <p>Tumor response assessments were performed at 9 weeks after the first dose, then every 6 weeks for the first year, and then every 12 weeks thereafter.</p>
            `} 
        />
    
        <StudyCopyBlock 
            title="Patient Characteristics in KEYNOTE&#8288;-&#8288;052 (N=370):"
            description={`
                <ul>
                    <li>ECOG PS 2: 32%; Baseline CrCl <60&nbsp;mL/min: 50%
                        <ul>
                            <li>9% with both ECOG PS 2 and baseline CrCl <60&nbsp;mL/min</li>
                        </ul>
                    </li>
                    <li>Class III heart failure, Grade 2 or greater peripheral neuropathy, and Grade 2 or greater hearing loss: 9%</li>
                    <li>The median age was 74 yrs, 77% were male; 85% had visceral metastases, including 21% with liver metastases; and 19% had a primary tumor in the upper tract</li>
                    <li>90% of patients were treatment naïve, and 10% received prior adjuvant or neoadjuvant platinum-based chemotherapy</li>
                </ul>
            `} 
            definitions={`
                BICR = blinded independent central review; CrCl = creatinine clearance; ECOG PS = Eastern Cooperative Oncology Group performance status; IV = intravenous; OS = overall survival; PFS = progression-free survival; RECIST v1.1 = Response Evaluation Criteria In Solid Tumors v1.1.
            `} 
        />

    </ComponentWrapper>
);

export default UC_1L;
