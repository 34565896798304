import React from 'react';
import styled from 'styled-components';
import { SIDEBAR_BREAKPOINT } from '../constants/layout';
import * as colors from '../util/colors';


export const MultiColumnWrapper = styled.div`
    background-image: linear-gradient(to right, ${colors.cloud} 0%, ${colors.cloud} 50%, ${colors.mist} calc(50% + 1px), ${colors.mist} 100%);
    
    > div {
        display: grid;
        //yes, this weird format is correct: https://developer.mozilla.org/en-US/docs/Web/CSS/grid-template-areas
        grid-template-areas: "main"
                            "sidebar";
        max-width: 1032px;
        margin: 0 auto;
        

        > *:last-child{
            background: ${colors.mist}
        }

        @media (min-width: ${SIDEBAR_BREAKPOINT}) {
            grid-template-columns: 1fr 252px;
            grid-template-areas: "main sidebar";
        }
    }
    
`;

export const TemplateColumnWithSidebarContainer = (props) => {
    return (
        <MultiColumnWrapper>
            <div>
                {props.children}
            </div>
        </MultiColumnWrapper>
    )
}

const TemplateColumn = props => {
    const maxWidth = props.maxWidth;
    // pass maxWidth attribute to children, which is used by layout components like PageSection to constrain the width of the visible content
    const children = React.Children.map(props.children, (child) => {

        return React.cloneElement(child, {
            maxWidth: (maxWidth) ? maxWidth : 992,
        });
    });
    return <div data-template-column={`${props.id}`}>{children}</div>;
};

export default TemplateColumn;
