import React from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import TemplateColumn, { TemplateColumnWithSidebarContainer } from '../../layouts/TemplateColumn';
import { PageHeader, PageSection, PageSubSection, RelatedContent, CrossLink, IndicationPageIntro, AnchorLinks, EndpointDisplay, ComparisonTable, NCCNcallout } from '../../components';
import UC_1L from '../../components/StudyDesign/__study-design/uc-1l'
import styled from 'styled-components';
import { jobCodes } from '../../util/data/jobCodes';

// SEO INFORMATION
const pageMeta = {
    title: `KEYNOTE-052 - Clinical Trial Results | HCP`,
    keywords: `keynote 052, urothelial carcinoma first-line monotherapy`,
    description: `Health care professionals can view clinical trial results for KEYNOTE-052 in certain patients with locally advanced or metastatic urothelial carcinoma (mUC).`,
    schemaJsonLD: [
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/efficacy/urothelial-carcinoma-first-line-monotherapy/","@type":"MedicalTrial","name":"KEYNOTE⁠-⁠052","description":"Clinical Findings from KEYNOTE⁠-⁠052","trialDesign":"A multicenter, single-arm, open-label trial"}`,
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/efficacy/urothelial-carcinoma-first-line-monotherapy/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`
    ]
}

//from /src/util/data/indications.js
const indicationId = 15;
const jobCode = jobCodes[15].jobCode;

const relatedContent = [
    {
        section: 'Resources',
        link: {
            url: '/resources/mechanism-of-action/',
            label: 'Mechanism of Action'
        }
    },
    {
        section: 'Resources',
        link: {
            url: 'https://www.merckaccessprogram-keytruda.com/',
            label: 'Access & Reimbursement Support'
        }
    }
];

const crossLinkContent = {
    sections: [
        {
            heading: "Efficacy for Other Indications",
            items: [
                { text: '2L Urothelial Carcinoma Monotherapy Post-Platinum Failure', url: '/efficacy/urothelial-carcinoma-second-line-monotherapy/' },
                { text: '1L Combination Therapy Advanced Urotherial Cancer', url: '/efficacy/urothelial-cancer-first-line-combination-therapy/' },
            ]
        },
        {
            heading: "Related Information",
            items: [
                { text: 'Selected Adverse Reactions', url: '/safety/adverse-reactions/urothelial-carcinoma-first-line-monotherapy/' },
                { text: 'Dosing', url: '/dosing/options/' },
            ]
        },
    ],
    interestLinks: {
        heading: "You Might Be Interested In",
        items: [
            { eyebrow: 'Resources', text: 'Mechanism of Action', url: '/resources/mechanism-of-action/' },
        ],
    }
};

const UCSpecificORRChart = styled(EndpointDisplay)`
     > div > div > div > div:first-child { //UC PRT threatened to hold up the 11/21 launch unless the Endpoint Display was modified and the percentages in RateTiles and ResponseTiles made to match size
        span {
            &:nth-child(2){
                font-size: clamp(35px, 7.8vw, 44px); 
                sup {
                    font-size: max(42%, 10px);
                    vertical-align: top;
                    position: relative;
                    top: 0.2em;
                }
            }
        }
    }
`; 

const Page = ({ location }) => {

    return (
        <DefaultLayout indicationId={indicationId} jobCode={jobCode} pageMeta={pageMeta} location={location}>
            <div data-template-name="template-e">
                {/* Begin Intro Column */}
                <TemplateColumn id="template-e-intro" maxWidth={824}>
                    <PageHeader title="Clinical Trial Results" />
                    <PageSection bgColor="white">
                        <IndicationPageIntro indicationId={indicationId} />
                    </PageSection>
                </TemplateColumn>
                {/* End Intro Column */}
                <TemplateColumnWithSidebarContainer>
                    {/* Begin Main Column */}
                    <TemplateColumn id="template-e-main">
                        <PageSection bgColor="cloud">
                            <AnchorLinks items={[
                                { label: 'Clinical Findings from KEYNOTE&#8288;-&#8288;052' }, 
                                { label: 'Study Design for KEYNOTE&#8288;-&#8288;052' }]} />
                        </PageSection>
                        <PageSection bgColor="cloud" title="Clinical Findings from KEYNOTE&#8288;-&#8288;052">
                            <PageSubSection title="Objective Response Rate and Duration of Response in 370 Platinum-Ineligible Patients With Locally Advanced or Metastatic Urothelial Carcinoma">

                                <UCSpecificORRChart title="Objective Response Rate (ORR)" limitation="In 370 patients with locally advanced or metastatic urothelial carcinoma" cards={
                                    [
                                        {
                                            dataType: 'ORR',
                                            label: 'KEYTRUDA 200&nbsp;mg Q3W IV',
                                            rate: '29%',
                                            ci: '95% CI, 24–34',
                                            completeResponseRate: '10%',
                                            partialResponseRate: '20%',
                                        }
                                    ]
                                }
                                />
                                <ComparisonTable title="Duration of Response (DOR)" 
                                definitions="+ Denotes ongoing response."
                                data={[
                                    [
                                        {
                                            legendTitle: 'KEYTRUDA 200&nbsp;mg Q3W IV',
                                            label: '',
                                            dataPoint: '',
                                            dataSubPoint: '',
                                        },
                                    ],
                                    [
                                        {
                                            label: 'Median DOR',
                                            dataPoint: '33.4 months',
                                            dataSubPoint: '(range: 1.4+ to 60.7+ months)'
                                        },
                                    ],
                                    [
                                        {
                                            label: 'Median Follow-Up Time',
                                            dataPoint: '11.4 months',
                                            dataSubPoint: '(range: 0.1 to 63.8 months)'
                                        },
                                    ]
                                ]}
                                additionalInformation={[
                                    {
                                        title: 'Additional Information',
                                        text: `
                                        <p>In KEYNOTE&#8288;-&#8288;361—a multicenter, randomized, open-label, active-controlled study in 1010 previously untreated patients. The safety and efficacy of KEYTRUDA
                                        in combination with platinum-based chemotherapy for previously untreated
                                        patients with locally advanced or metastatic urothelial carcinoma has not been
                                        established.</p>
                                        <p>The study compared KEYTRUDA with or without platinum-based chemotherapy
                                        to platinum-based chemotherapy alone. Among the patients receiving
                                        KEYTRUDA plus platinum-based chemotherapy, 44% received cisplatin and
                                        56% received carboplatin.</p>
                                        <p>The study did not meet its major efficacy outcome measures of improved PFS or
                                        OS in the KEYTRUDA plus chemotherapy arm compared to the chemotherapy-alone
                                        arm.</p>
                                        <p>Additional efficacy endpoints, including improvement of OS in the KEYTRUDA
                                        monotherapy arm, could not be formally tested.</p>
                                           `
                                    },

                                ]}
                                />
                            </PageSubSection>
                        </PageSection>

                        <PageSection title="Study Design for KEYNOTE&#8288;-&#8288;052">
                            <UC_1L />
                        </PageSection>

                    </TemplateColumn>
                    {/* End Main Column */}
                    <TemplateColumn id="template-e-sidebar">
                        <PageSection bgColor="mist">
                            <CrossLink {...crossLinkContent} />
                        </PageSection>
                    </TemplateColumn>
                </TemplateColumnWithSidebarContainer>
                <TemplateColumn id="template-e-secondary">
                    <PageSection bgColor="keytrudaGreen">
                        <RelatedContent items={relatedContent} />
                    </PageSection>
                </TemplateColumn>
            </div>
        </DefaultLayout>
    );
};

export default Page;
